
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import client from '@/shared/plugins/content';

@Component({
  layout: 'AppMain',
  name: 'help-center',
})
export default class HelpCenterIndex extends Vue {
  posts: any[] = [];

  mounted() {
    client
      .getEntries({
        content_type: 'helpPosts',
      })
      .then((response: { items: any[] }) => {
        this.posts = response.items;
      });
  }
}
